import Unit from './Unit.vue'
import UnitCreate from './Create.vue'
import UnitEdit from './Edit.vue'

export default [
    {
        path: '/unit',
        name: 'Unit',
        component: Unit
    },
    {
        path: '/unit/create',
        name: 'UnitCreate',
        component: UnitCreate
    },
    {
        path: '/unit/edit/:id',
        name: 'UnitEdit',
        component: UnitEdit
    },
]
